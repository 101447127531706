/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
%vertical-rhythm {
    margin-bottom: $spacing-unit / 2;
}

html > body article {
    & p {
        font-size: 1.2em;
    }
    & h1 {
        font-size: 1.8em;

        & > a {
            font-size: 0.8em;
        }
    }
    & h2 {
        font-size: 1.5em;
    }
    & ul,ol > li {
        font-size: 1.2rem;
    }
    & code.highlighter-rouge {
        font-size: 1.0em;
        color: #666;
        white-space: break-spaces;
        word-break: break-all;
    }
    & pre.highlight > code {
        font-size: 1.2em;
    }
    & blockquote p {
        font-size: 0.95em;
        color: #666;
        border-left: 4px solid lightgray;
        border-radius: 2px;
        padding-left: 8px;
    }
}

.search svg {
    max-width: initial;
}
